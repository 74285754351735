import { mapState, mapGetters } from 'vuex';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
// import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnDownloadDocItem from '../../../shared/components/KnDownloadDocItem.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
// import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
// import KnImageDocItem from '../../../shared/components/KnImageDocItem.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
// import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
// import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import {
  assignReportCardToStudent,
  fetchStudents,
  postReportCard,
  putReportCard,
} from '../../helpers/reportCardOptions';
import {
  // eslint-disable-next-line no-unused-vars
  getFullName,
  extractDocNameFromURL,
  createFormDataFromObject,
} from '../../../shared/helpers/dataUtils';
import {
  canAdd,
  canChange,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
export default {
  name: 'KnFormReportCard',
  components: {
    KnBackToTopButton,
    KnDocItem,
    KnDownloadDocItem,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormTitle,
    KnLocalAlert,
    KnTabs,
    KnTextField,
  },
  mixins: [],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      valueDeterminate: 100,
      routerName: 'Boletas',
      resource: 'boleta',
      valid: true,
      reportCard: {
        id: null,
        nombre_boleta: '',
        periodo: '',
        documento: null,
        institucion_educativa: null,
        autor: null, // usuario logueado
        estatus_sistema: true,
      },
      reportCardId: null,
      reportCardImages: [],
      reportCardGroups: [],
      docFile: null,
      docName: null,
      studentId: null,
      studentIdBk: null,
      students: [],
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['userId']),
    title() {
      return this.entity === null ? 'Agregar boleta' : 'Editar boleta';
    },
    isNewMode() {
      return this.entity === null;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Boleta registrada con exito!'
        : 'Boleta actualizada con exito!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    showDocItem() {
      return (
        this.isNewMode ||
        (!this.isNewMode && this.reportCard.documento === null)
      );
    },
  },
  async created() {
    this.loading = true;
    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;
    await this.getStudents();
    if (!this.isNewMode) {
      // console.log('Se deben llenar los campos');
      this.reportCardId = this.entity.id;
      this.fillReportCard();
    }
    setTimeout(() => (this.showAlert = false), 1000);
  },
  methods: {
    async getStudents() {
      const { ok, data, message } = await fetchStudents({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 200,
      });
      if (ok) {
        this.students = data;
      } else {
        console.error('getStudents', message);
      }
    },
    async createReportCard() {
      try {
        this.alertText = 'Creando boleta';
        this.reportCard.autor = this.userData.id;
        this.reportCard.institucion_educativa = this.institutionId;
        const reportCardFormData = createFormDataFromObject(this.reportCard);
        reportCardFormData.append('documento', this.docFile.file);
        const { ok, data, message } = await postReportCard(reportCardFormData);
        if (!ok) {
          this.errors.push(message);
        } else {
          this.reportCardId = data.id;
          // console.log('Alumno seleccionado', this.studentId);
          if (this.studentId !== null) {
            this.alertText = 'Asignando boleta a alumno';
            const { ok: okAssign, message: messageAssign } =
              await assignReportCardToStudent(this.studentId, [
                this.reportCardId,
              ]);
            if (!okAssign) {
              this.errors.push(messageAssign);
            }
          }
        }
      } catch (error) {
        this.errors.push(error);
        console.error('Error al intentar crear boleta. ' + error);
      }
    },
    async updateReportCard() {
      try {
        this.alertText = 'Actualizando boleta';
        // console.log('Boleta a actualizar', this.reportCard);
        this.reportCard.documento = null;
        const reportCardFormData = createFormDataFromObject(this.reportCard);
        if (this.docFile !== null && this.reportCard.documento === null) {
          reportCardFormData.append('documento', this.docFile.file);
        }
        const { ok, data, message } = await putReportCard(reportCardFormData);
        if (!ok) {
          this.errors.push(message);
        } else {
          this.reportCardId = data.id;
          // En la actualizacion la asignacion de boletas
          // se debe llevar a acabo solo si hay un cambio
          // en el alumno seleccionado
          // console.log('Alumno seleccionado', this.studentId);
          if (this.studentId !== null && this.studentId !== this.studentIdBk) {
            this.alertText = 'Asignando boleta a alumno';
            const { ok: okAssign, message: messageAssign } =
              await assignReportCardToStudent(this.studentId, [
                this.reportCardId,
              ]);
            if (!okAssign) {
              this.errors.push(messageAssign);
            }
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar boleta. ' + error);
        console.error('Error al intentar actualizar boleta. ' + error);
      }
    },
    fillReportCard() {
      // Respaldo de imagenes
      // this.reportCardImages = this.entity.imagenes_boletas;
      // Respaldo de grupos
      // this.reportCardGroups = this.entity.grupos;
      this.docName = extractDocNameFromURL(this.entity.documento);
      this.studentId = this.entity.student ? this.entity.student.id : null;
      this.studentIdBk = this.studentId;
      this.reportCard = {
        id: this.entity.id,
        nombre_boleta: this.entity.nombre_boleta,
        periodo: this.entity.periodo,
        documento: this.entity.documento,
        estatus_sistema: this.entity.estatus_sistema,
        autor: this.userId,
        institucion_educativa: this.institutionId,
      };
    },
    getFullName: getFullName,
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.alertText = 'Cargando...';
        this.showAlert = true;
        if (this.isNewMode) {
          // console.log('A crear');
          if (canAdd(this.resource)) {
            await this.createReportCard();
            this.completeAlert();
          } else {
            this.insufficientPermissionAlert();
          }
        } else {
          // console.log('A actualizar');
          if (canChange(this.resource)) {
            await this.updateReportCard();
            this.completeAlert();
          } else {
            this.insufficientPermissionAlert();
          }
        }
      }
    },
    completeAlert() {
      this.loading = false;
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    insufficientPermissionAlert() {
      this.alertType = 'info';
      this.alertText = insufficientPermissionsMessage();
      this.alertColor = 'warning';
      this.loading = false;
      this.showAlert = true;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.reportCard = {
        id: null,
        nombre_boleta: '',
        periodo: '',
        documento: null,
        institucion_educativa: null,
        autor: this.userId, // usuario logueado
        estatus_sistema: true,
      };
    },
    saveFile(file) {
      // console.log('El archivo seleccionado:', file);
      this.docFile = file;
    },
    clearFile() {
      this.docFile = null;
      this.reportCard.documento = null;
    },
  },
};
